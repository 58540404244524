import * as React from "react"
import { useRef, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButton from "../components/share-button"
import BtnLeft from "../components/button-left"

const BlogPostTemplate = ({ data, location, navIsHidden }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const featuredImage = getImage(post.frontmatter.featuredImage)
  const scrollToRef = useRef(null)
  const tags = post.frontmatter.tags
  const osowska = tags.includes("osowska")
  useEffect(() => {
    scrollToRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  })

  return (
    <Layout location={location} title={"Galeria"} ref={scrollToRef}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <div
        className="content-container-gallery has-background-dark is-6"
        ref={scrollToRef}
      >
        <div className="columns is-mobile">
          <div className="column has-text-left ml-3">
            <BtnLeft
              className=""
              path={`${osowska ? "/przyjaciele" : "/galeria"}`}
              description={`${
                osowska ? "DO STRONY PRZYJACIELE" : "DO GALERII"
              }`}
            />
          </div>
          <div className="column has-text-right">
            <ShareButton location={location} />
          </div>
        </div>
      </div>

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="has-text-centered">
          <h1
            className="mb-2 is-size-5 is-family-secondary has-text-weight-light has-letter-spacing-1 is-uppercase"
            itemProp="headline"
          >
            {post.frontmatter.title}
          </h1>
          <div class="columns is-mobile mb-2">
            <div class="column is-10 is-offset-1">
              <p className="has-text-weight-light has-letter-spacing-1 is-italic">
                {post.frontmatter.description}
              </p>
            </div>
          </div>
        </header>

        <section className="mt-2 has-text-centered">
          <GatsbyImage
            objectFit="contain"
            objectPosition="center center"
            image={featuredImage}
            alt={`Obraz - tytuł here`}
          />
        </section>

        <section className="has-text-centered is-size-7 has-text-weight-light has-letter-spacing-1 is-uppercase mb-6">
          <div className="columns is-mobile">
            <div className="column has-text-left">
              {post.frontmatter.dimensions &&
                `Wymiary: ${post.frontmatter.dimensions}`}
            </div>
            <div className="column is-hidden-touch">
              Dodany do galerii: {post.frontmatter.date}
            </div>
            <div className="column has-text-right">
              {post.frontmatter.paintingDate &&
                `Namalowany w: ${post.frontmatter.paintingDate}`}
            </div>
          </div>
        </section>

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <nav
        className={`columns is-mobile blog-post-nav mt-6 mb-6 pb-6 has-text-weight-light has-letter-spacing-1 is-uppercase is-size-7 ${
          osowska ? "is-hidden" : ""
        }`}
      >
        <div className="column has-text-left">
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </div>
        <div className="column has-text-right">
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </div>
      </nav>
      <section className="mt-3"></section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        dimensions
        paintingDate
        date(formatString: "DD.MM.YYYY")
        description
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    previous: markdownRemark(
      id: { eq: $previousPostId }
      frontmatter: { inGallery: { eq: true } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(
      id: { eq: $nextPostId }
      frontmatter: { inGallery: { eq: true } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
